#hero {
    height: 100vh;
    background: rosybrown;
    background: var( --background-color );
	z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
    overflow: hidden;
    color: var( --hero-text-color );  
}

#logo, #text-logo {
    z-index: 1;

    // setup for lea
    top: var( --page-frame );
    left: var( --page-frame );
    position: absolute;
    display: block;
    * {
        font-family: var( --font-family );
        font-size: calc( var( --font-size ) * 2 );
        letter-spacing: -0.02em;
        color: #aa7761;
    }
    button {
        background: transparent;
        // background: #b38d7700;
        padding: 0.7em;
        // outline: solid var( --stroke ) #b38d77;
        // outline-offset: calc( var( --stroke ) * -1 );

        &:hover {
            // background: #b38d77;
            // outline: 0;
            // outline-offset: 0;
            * {
                color: #ef864a;
            }
        }
    } 

}
#logo {
    line-height: 0;
    max-width: 500px;
    min-width: 200px;
    width: var( --logo-width );
    height: auto;
    overflow: hidden;
    
    picture {
        > img {
            width: 100%;
        }
        height: 100%;
        display: block;
    }
    
}

.logo-angled {
    -webkit-transform: rotate( var( --logo-angle ) );
        -ms-transform: rotate( var( --logo-angle ) );
            transform: rotate( var( --logo-angle ) );
}

// lea’s grid
// background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
// background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
// background-image: -webkit-linear-gradient(white 2px, transparent 2px),
//                   -webkit-linear-gradient(0, white 2px, transparent 2px),
//                   -webkit-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
//                   -webkit-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px);
// // background-image: -moz-linear-gradient(white 2px, transparent 2px),
// //                   -moz-linear-gradient(0, white 2px, transparent 2px),
// //                   -moz-linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
// //                   -moz-linear-gradient(0, rgba(255,255,255,.3) 1px, transparent 1px);
// // background-image: linear-gradient(white 2px, transparent 2px),
// //                   linear-gradient(90deg, white 2px, transparent 2px),
// //                   linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px),
// //                   linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px);
// -pie-background: linear-gradient(white 2px, transparent 2px) -2px -2px / 100px,
//                  linear-gradient(90deg, white 2px, transparent 2px) -2px -2px / 100px,
//                  linear-gradient(rgba(255,255,255,.3) 1px, transparent 1px) -1px -1px / 20px,
//                  linear-gradient(90deg, rgba(255,255,255,.3) 1px, transparent 1px) -1px -1px / 20px,
//                  #269;
// behavior: url(/pie/PIE.htc);
// }
