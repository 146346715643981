:root {

  // TODO: sort and use custom css for each project
  
  // basics
  --unit: 20px;
  // --page-frame-big: calc( var( --unit ) * 5 );
  --page-frame-big: calc( var( --unit ) * 1 ); // setup for lea
  --page-frame: var(--page-frame-big);
  // gallery items
  --min-width: 320px;
  --item-width: 328px;
  --item-big-width: calc( var( --item-width ) * 1.5 );
  --item-max-height: 80vh;
  --indent-gap: calc( var( --font-size ) * 2 );
  // global layout specs
  --freedom: var(--page-frame-big);
  --button-space: var(--font-size);
  --logo-width: 62vw;
  --logo-height: 40px;
  --header-height: calc( calc( var( --page-frame ) * 2 ) + var( --logo-height ) );
  --footer-height: calc( var( --font-size ) + var( --font-height ) + var( --page-frame  ) );
  --bouncer-size: 60px;
  // grid
  --grid-width: calc( 100% - calc( var( --page-frame ) * 2 ) );
  --columns: 1;
  --alignment: "left";

  // strokes, borders, outlines
  --stroke: 2px;


  // color
  --background-color: White;
  --background-color-alpha: #ffffff00;
  --primary-color: Black;
  --secondary-color: Gray;
  --tertiary-color: #eee;
  --highlight-color: Red;
  --lowlight-color: #FFF59D;
  --link-color: CornflowerBlue;
  --positive-color: Aquamarine;
  --hero-text-color: var(--primary-color);

  // typography
  --small-font: 0.7em;
  // -- for lea
  --font-family: mrs-eaves,
                 serif;
  --font-weight: 400;
  --font-style: normal;
  --font-size: 1rem;
  --font-height: 1.4em;

  // -- for sacha
  // --bold: "Avenir Next W04 Bold",
  //         "Futura Bold",
  //         "Helvetica Neue Bold", 
  //         "Helvetica Bold", 
  //         "Arial Bold", 
  //         sans-serif;
}
