
footer * ::selection, 
.social::selection, 
#showcase * ::selection, 
#hero::selection, 
#bouncer-text > span::selection {
    background-color: transparent;
}

h1, h2, h3, h4, h5, h6, strong, .fat {
    margin: 0;
    display: block;
    font-family: "Avenir Next W04 Bold",
                 "Futura Bold",
                 "Helvetica Neue Bold", 
                 "Helvetica Bold", 
                 "Arial Bold", 
                 sans-serif;
    font-weight: normal;
    letter-spacing: 0.03em;
}
h1, h2, h3, h4, h5, h6 {
	font-size: 1em;
}

p {
    margin-bottom: var( --font-height );
}
ol, ul, li {
    list-style: none;
}
a {
    color: black;
    text-decoration: none;
}
section {
	height: 100%;
}
hr {
    border: 0;
    height: 1px;
    background: var( --secondary-color );
}
img {
	pointer-events: none;
}
progress {
	-webkit-appearance: none;
	   -moz-appearance: none;
			appearance: none;
	
	border: none;
	width: 100%;
	height: 6px;
	left: 0;
	top: 0;
	position: absolute;
    
    &::-webkit-progress-bar {
        background: var( --tertiary-color );
    }
    &::-webkit-progress-value {
        background: var( --primary-color );
    }
}

// TODO: refactor!
.empty {
    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50% );
    }
}
.wallpaper {
    background: purple;
}


.break-narrow {
    display: none;
}
.break-wide {
    display: inline;
}
.breaker {
	display: none;
}

.uppercase {
    text-transform: uppercase;
}
.big-text {
    font-size: 2em;
    line-height: 1.1em;
    letter-spacing: 0.01em;
}
.footnote {
    padding-left: .1em;
    margin: 0 !important;
}
.footnotes {
	font-size: var( --small-font );
	line-height: calc( var( --small-font ) * 2.3 );
    margin-top: calc( var( --font-height ) * 3 );
}
.current {
    pointer-events: none;
    cursor: default;
}
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
           -o-user-select: none;
              user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.invisible {
	display: none !important;
}

/* buttons */
button, 
.social a,
#contact a,
#imprint a {
	padding: var( --button-space ) var( --button-space ) calc( var( --button-space ) * 0.85 );
	// background: var( --background-color );
    color: var( --primary-color );
	cursor: pointer;
	line-height: 1em;

	-webkit-transition: all 150ms linear;
       -moz-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
         -o-transition: all 150ms linear;
            transition: all 150ms linear;
}
#imprint-button {
    width: 10em;
}
#privacy-button {
    width: 15em;
}
button {
    &:focus, 
    &:active {
        outline: none;
    }
}

button:hover,
.social a:hover,
#contact a:hover,
#imprint a:hover,
#privacy a:hover {
	background: black;
	color: white;

    font-family: "Avenir Next W04 Bold",
                 "Futura Bold",
                 "Helvetica Neue Bold", 
                 "Helvetica Bold", 
                 "Arial Bold", 
                 sans-serif;
    font-weight: normal;

	-webkit-transition: all 150ms linear;
       -moz-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
         -o-transition: all 150ms linear;
            transition: all 150ms linear;

}
.hidden-button {
    visibility: hidden;
}
.dropdown-button {
    cursor: pointer;
    &:hover {
        color: #666;
        + .dropdown-arrow {
            opacity: 1;
            -webkit-transition: all 150ms linear;
               -moz-transition: all 150ms linear;
                -ms-transition: all 150ms linear;
                 -o-transition: all 150ms linear;
                    transition: all 150ms linear;
        }
    }
}



#background {

    width: 100vw;
    height: var( --vh );
    height: 100vh;
    z-index: 0;
    div, figure {
        background: no-repeat center;
        background-size: cover;
        height: 100%;
        overflow: hidden;
    }
    img, video {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-transform: translate( -50%, -50% );
            -ms-transform: translate( -50%, -50% );
                transform: translate( -50%, -50% );
    }
}
.backdrop {

    -webkit-backdrop-filter: blur( 20px ) saturate( 180% );
            backdrop-filter: blur( 20px ) saturate( 180% );
    
}


.rise {
    animation: 350ms change-height 1s forwards ease-out;
}
.change-color {
    animation: 700ms fade-to-white 2s forwards ease-in-out;    
}


.freedom-above {
    padding-top: var( --freedom );
}
.freedom-below {
    padding-bottom: var( --freedom );
}
.bottom-gap {
    margin-bottom: calc( var( --page-frame ) * 2 );
}
.appear {
    opacity: 0;
	-webkit-animation: 700ms fade-in forwards ease-in-out;
			animation: 700ms fade-in forwards ease-in-out;
}
.appear-slow {
    opacity: 0;
	-webkit-animation: 7s fade-in forwards ease-in;
			animation: 7s fade-in forwards ease-in;
}
.appear-later {
    opacity: 0;
	-webkit-animation: 700ms fade-in 1s forwards ease-in-out;
			animation: 700ms fade-in 1s forwards ease-in-out;
}
.appear-delayed {
    opacity: 0;
	-webkit-animation: 700ms fade-in 3s forwards ease-in-out;
			animation: 700ms fade-in 3s forwards ease-in-out;
}
.hide {
	opacity: 0;
}
.vanish {
    opacity: 1;
	-webkit-animation: 700ms fade-out forwards ease-in-out;
			animation: 700ms fade-out forwards ease-in-out;
}
.active {
	opacity: 1;
	
    -webkit-transition: opacity .25s ease-in-out;
       -moz-transition: opacity .25s ease-in-out;
            transition: opacity .25s ease-in-out;
}
.inactive {
    pointer-events: none;
	opacity: 0.2;
	
    -webkit-transition: opacity .25s ease-in-out;
       -moz-transition: opacity .25s ease-in-out;
            transition: opacity .25s ease-in-out;
}
#bouncer-text {
	
	pointer-events: none;
	color: var( --primary-color );

	width: 140px;
    height: 60px;
    left: calc( 50% - 70px );
    top: calc( var( --vh ) - var( --page-frame-big ) );
    top: calc( 100vh - var( --page-frame-big ) );
    position: absolute;

	-webkit-animation: 2s bounce 2s infinite;
            animation: 2s bounce 2s infinite;
}

#bouncer {

    width: var( --bouncer-size );
    height: 60px;
    left: calc( 50% - calc( var( --bouncer-size ) / 2 ) );
    bottom: calc( var( --unit ) * 7 );
    position: absolute;
    display: block;
    z-index: 1;

    -webkit-animation: 2s bounce 2s infinite;
        -ms-animation: 2s bounce 2s infinite;
            animation: 2s bounce 2s infinite;

    &::before {
        top: calc( 50% - calc( var( --bouncer-size ) / 3 ) );
        left: calc( 50% - calc( var( --bouncer-size ) / 3 ) );
        width: calc( calc( var( --bouncer-size ) / 3 ) * 2 );
        height: calc( calc( var( --bouncer-size ) / 3 ) * 2 );
        position: absolute;
        display: block;
        border: 2px solid var( --background-color );
        border-width: 0px 0 2px 2px;
        content: "";

        -webkit-transform: rotate( -45deg );
            -ms-transform: rotate( -45deg );
                transform: rotate( -45deg );
    }

}

.dropdown-arrow {
	top: 0;
	position: absolute;
	-webkit-transform: translate( calc( var( --font-size ) * -2.75 ), calc( var( --font-size ) * 0.5 ) );
        -ms-transform: translate( calc( var( --font-size ) * -2.75 ), calc( var( --font-size ) * 0.5 ) );
            transform: translate( calc( var( --font-size ) * -2.75 ), calc( var( --font-size ) * 0.5 ) );
	opacity: 0;
	-webkit-transition: all 150ms linear;
       -moz-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
         -o-transition: all 150ms linear;
            transition: all 150ms linear;
}
.collapsed > .dropdown-arrow::before {
    -webkit-transform: rotate( 225deg );
        -ms-transform: rotate( 225deg );
            transform: rotate( 225deg );
}
.expanded > .dropdown-arrow::before {
    -webkit-transform: rotate( 315deg );
        -ms-transform: rotate( 315deg );
            transform: rotate( 315deg );
}
.dropdown-arrow::before {
    width: calc( var( --font-size ) / 5 );
    height: calc( var( --font-size ) / 5 );
    position: absolute;
    border: 2px solid var( --primary-color );
    border-width: 0px 0 1px 1px;
    content: "";
}


/* grid & flexbox */
.grid {
    margin-left: var( --page-frame );
    margin-right: var( --page-frame );
    display: grid;
    grid-template-columns: repeat( 12, calc( 100% / 12 ) ); 
    grid-template-rows: auto;
    justify-items: center;
}
.flexbox {
    width: 100%;
    grid-column: 1 / span 12;
    display: flex;
    align-items: center;
    align-content: center;
}
.row {
    flex-flow: row wrap;
    justify-content: center;
}
.column {
    flex-flow: column wrap;
    justify-content: flex-start;
}
.aligned-left {
    justify-content: flex-start !important;
}
.aligned-right {
    justify-content: flex-end !important;
}





.video-controls {
    opacity: 0.4;
    background: pink;
}


#back-to-top {
    margin-left: -16px;
    width: 32px;
    height: 32px;
    right: calc( var( --page-frame ) * 1.5 );
    bottom: calc( var( --page-frame ) * 1.5 );
    border: solid 1px;
    text-align: center;
    display: block;
    position: fixed;
    z-index: 10;
    border-radius: 16px;
    
    &::before {
        top: calc( 50% - 3px );
        left: calc( 50% - 6px );
        width: 12px;
        height: 12px;
        position: absolute;
        display: block;
        border: 2px solid var( --primary-color );
        border-width: 0px 0 1px 1px;
        content: "";
        
        -webkit-transform: rotate( 135deg );
            -ms-transform: rotate( 135deg );
                transform: rotate( 135deg );
    }    
}


/* lists */
.list {
    columns: 1 !important;
    max-width: 60em;
    padding-bottom: var( --font-height );
    h1 {
        padding: var( --font-height ) 0;
    }
    span {
        position: absolute;
        margin-left: calc( var( --indent-gap ) * -1);
        text-align: left;
    }
    li {
        display: block;
        position: relative;
        margin-left: var( --indent-gap );
    }
    ul li {
        margin: 0 !important;
    }

    a:hover {
        padding: var( --button-space ) var( --button-space ) calc( var( --button-space ) * 0.85 );
        background: black;
        color: white;
    }
}

.footnotes a:hover {
    padding: var( --button-space ) var( --button-space ) calc( var( --button-space ) * 0.85 );
    background: black;
    color: white;
}

.collapsed {
    ol, ul, p {
        display: none;
    }
}

.expanded {
    padding-bottom: var( --font-height );
    > {
        ol > li:last-child {
            padding-bottom: var( --font-height );
        }
        .no-gap > li:last-child {
            padding-bottom: 0;
        }
        ol > li:first-child > h3 {
            margin-top: var( --font-height );
        }
    }
    p {
        margin-top: var( --font-height );
    }
}


.social, .message {
    grid-column: 1 / last-line;
    padding: var( --page-frame );
}
.social h4 {
    font-size: 1.2em;
}
.message > h1 {
	color: var( --highlight-color );
}
.social li, #imprint li {
    display: block;
}
#contact {
    white-space: nowrap;
    margin-bottom: var( --unit );
}
#contact ul, #imprint ul {
    display: block;
    margin-top: var( --font-height );
}

#contact li {
    display: block;
}

#copyright {
    margin: var( --unit ) var( --page-frame );
}
/* imprint content */
#imprint {
    grid-column: 1 / span 12;
	align-self: start;
}
#disclaimer {
	max-width: 1310px;
}
#disclaimer, #privacy {
    text-align: left;
    width: 100%;
    grid-column: 1 / span 12;
}
#disclaimer p, #privacy .list {
    column-count: var( --columns );
    column-gap: var( --unit );
}
#disclaimer p {
    margin-top: var( --font-height );
}
#imprint *, #disclaimer *, #privacy * {
    letter-spacing: 0.01em;
}
#privacy h2, #privacy h3 {
    letter-spacing: -0.015em;
}

.space {
    display: inline;
}
.social br {
    display: none;
}


// shapes
.shape {
    display: block;
    -webkit-transition: all 600ms linear;
       -moz-transition: all 600ms linear;
        -ms-transition: all 600ms linear;
         -o-transition: all 600ms linear;
            transition: all 600ms linear;
    
    &:hover {
        // background: var( --highlight-color );
        background: #aa7761;
        -webkit-transition: all 150ms linear;
           -moz-transition: all 150ms linear;
            -ms-transition: all 150ms linear;
             -o-transition: all 150ms linear;
                transition: all 150ms linear;
     }
}
.circle {
    background: var( --primary-color );
    background: #000;
    width: calc( var( --unit ) * 1 );
    height: calc( var( --unit ) * 1 );
    border-radius: 50%;
}
