.preloader {
    // width: var( --item-width );
    width: 100px;
    height: 100px;
    background: transparent;
    img {
        margin: auto;
    }
    transform: translate( -50%, -50% );
    left: 50%;
    top: 45%;
    position: absolute;
}
.preloader-background {
    // border: solid 1px #eee;
    background: #eee !important;
}
.dark-background {
    background: var( --primary-color );
}
