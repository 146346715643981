footer {
    // font-size: 0.7em;
    height: var( --footer-height );
    ul {
        flex-flow: row wrap;
        justify-content: center;
        padding: 0 calc( var( --page-frame ) - var( --button-space ) );
    }
    button {
        width: 9em;
        font-size: var( --small-font );
        letter-spacing: 0.15em;
        background: var( --background-color );
        opacity: 0.9;
    }
}
