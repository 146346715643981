header {
    &::selection, * ::selection {
        background-color: transparent;
    }
    button, 
    .services {
        font-size: var( --small-font );
        letter-spacing: 0.15em;
        background: var( --background-color );
        opacity: 0.9;
    }
    position: absolute;
    z-index: 10;
    width: 100%;
    height: var( --header-height );
    transition: transform 500ms ease-in-out;
    nav {
        padding: 0 var( --page-frame );
        height: 100%;
        min-width: var( --min-width );
        align-content: center !important;
        justify-content: flex-end !important;
        li {
            display: inline;
        }
        button {
            align-self: flex-end;
            width: 9em;
            height: var( --logo-height );
            line-height: 0;
            // margin: 0 calc( var( --page-frame ) - var( --button-space ) );
        }
    }
}


.header-space {
    padding-top: var( --header-height );
}

.left-aligned {
    left: var( --page-frame );
}
.indent {
    left: calc( var( --page-frame ) * 3 );
}
.centered {
    left: 50%;
    -webkit-transform: translate( -50% );
        -ms-transform: translate( -50% );
            transform: translate( -50% );
}
.header-logo {
    margin: var( --page-frame );
    height: var( --logo-height );
    min-height: var( --logo-height );
    position: absolute;
    display: block;
    cursor: pointer;
    source, img {
        height: 100%;
    }
    picture {
        position: absolute;
        height: 100%;
        width: auto;
        left: 0;
    }
}


.services {

    left: 50%;
    line-height: var( --font-size );
    display: none;
    position: absolute;
    pointer-events: none;
    cursor: default;
    
    -webkit-transform: translate( -50%, 0 );
        -ms-transform: translate( -50%, 0 );
            transform: translate( -50%, 0 );
    
    ul li {
        padding: 0.5em;
    }
}


.border-bottom {
    position: absolute;
    left: var( --page-frame );
    width: calc( 100% - calc( var( --page-frame ) * 2 ) );
    opacity: 0;
    bottom: 0;
    border-bottom: 1px solid var( --tertiary-color ); 
}
.line {
    margin-left: var( --page-frame );
    width: calc( 100% - calc( var( --page-frame ) * 2 ) );
    border-bottom: 1px solid var( --tertiary-color ); 
}
.stick-to-top {
    position: fixed !important;
    top: 0 !important;
}
.move-up {
	animation: moveUp 400ms ease-out forwards;
}
.move-down {
	animation: moveDown 400ms ease-out forwards;
}
.with-transition {
	-webkit-transition: all 150ms linear;
       -moz-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
         -o-transition: all 150ms linear;
            transition: all 150ms linear;
}

.stick-to-second-section {
    position: absolute;
    top: var( --vh );
	top: 100vh;
}
