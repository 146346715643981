.instagram {
  * {
    text-transform: uppercase;
    font-family: var( --bold );
  }
  button {
    background: var( --background-color );
    &:hover {
      background: var( --primary-color );
      } 
  }
}

.instagram-button {
  padding: calc( var( --button-space ) * 0.7 );
  height: var( --logo-height );
  width: var( --logo-height );

  picture {
    height: 100%;
    display: flex;
    img {
      height: 100%;
    }
  }
  // opacity: 0.1;
  // position: absolute;
  // z-index: 10;
  // width: 1.5em;
  // height: 1.5em;
  // right: calc( var( --page-frame-big ) * 2 );
  // top: 51%;
  // -webkit-transform: translate(50%, -50%);
  //     -ms-transform: translate(50%, -50%);
  //         transform: translate(50%, -50%);
  // -webkit-transition: all .3s ease-out;
  //    -moz-transition: all .3s ease-out;
  //     -ms-transition: all .3s ease-out;
  //      -o-transition: all .3s ease-out;
  //         transition: all .3s ease-out; 
  &:hover {
    background: black;
  }
}
