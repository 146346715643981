#showcase {
	min-height: var( --vh );
	min-height: 100vh;
}
#showcase-title {
    grid-column: 1 / span 12;
    grid-row: 2 / span 1;
    padding-top: 0.5em;
    h1 {
        margin: 0;
    }
}

#showcase-content {
    grid-row: 3 / span 1;
    min-height: var( --vh );
    min-height: 100vh;
    align-content: center;
    > div {
        max-width: 100%;
        position: relative;
        margin-bottom: var( --unit );
        img {
            display: block;
//             max-height: var( --item-max-height );
            max-width: 100%;
            object-fit: contain;
        }
        video {
            display: block;
            max-height: var( --item-max-height );
            max-width: 100%;
            object-fit: contain;
        }
    }
}
