#gallery {
	align-items: flex-start;
    padding-top: var( --header-height );
}


.teaser {
    position: relative;
    margin: 0 var( --unit ) calc( var( --unit ) * 2 ) var( --unit );
    font-size: 2em;
    cursor: pointer;

    -webkit-transition: all .5s ease-in-out;
       -moz-transition: all .5s ease-in-out;
            transition: all .5s ease-in-out;
    // &:hover {
    //     .teaser-element {

            // border: solid 1px var( --primary-color );

            // -webkit-filter: grayscale(100%);
            //         filter: grayscale(100%);
            
            // -webkit-transition: all .3s ease-in-out;
            //    -moz-transition: all .3s ease-in-out;
            //         transition: all .3s ease-in-out;
            
            // img, video {
            //     opacity: 0.1;

            //     -webkit-transition: all .3s eas-in;
            //        -moz-transition: all .3s eas-in;
            //             transition: all .3s eas-in;
            // }
        // }
        // .teaser-title {
            // opacity: 1;

            // -webkit-transition: opacity .15s ease-in-out;
            //    -moz-transition: opacity .15s ease-in-out;
            //         transition: opacity .15s ease-in-out;
        // }
    // }
}

.teaser-hover {
  .teaser-element {
    // background: var( --primary-color );

    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);

    -webkit-transition: all .3s ease-in-out;
       -moz-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
    
    img, video {
      opacity: 0.2;
      -webkit-transition: all .3s eas-in;
         -moz-transition: all .3s eas-in;
              transition: all .3s eas-in;
    }
  }
  .teaser-title {
    opacity: 1;
    -webkit-transition: opacity .15s ease-in-out;
       -moz-transition: opacity .15s ease-in-out;
            transition: opacity .15s ease-in-out;
  }
}

.teaser-element {

    background: var( --primary-color );
    border: none;
    position: relative;
    pointer-events: none;
    margin: auto;
    opacity: 1;
    display: block;
    max-height: var( --item-max-height );

    -webkit-transition: all .3s ease-in-out;
       -moz-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;

    figure {
        width: 100%;
        height: 100%;
    }
    img, video {
        width: 100%;
        height: 100%;

        -webkit-transition: all .3s ease-out;
           -moz-transition: all .3s ease-out;
                transition: all .3s ease-out;
    }
}
// #bouncer-text > span::selection {
//   background-color: transparent;
// }

.teaser-title {

  &::selection {
    background-color: transparent;
  }

    z-index: 1;
    width: 95%;
    top: 50%;
    left: 50%;
    position: absolute;
    opacity: 0;
    color: var(--background-color);
    line-height: 1.2em;

    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    
    -webkit-transition: opacity .25s ease-in-out;
       -moz-transition: opacity .25s ease-in-out;
            transition: opacity .25s ease-in-out;
}


.item {
    display: flex;
    flex-direction: column;
}
.citizen {
    order: 0;
    flex: 0 1 0;
    
    .teaser-element {
        max-width: var( --item-width );
    }
    
    .one-word-per-line {
        word-spacing: var( --item-width );
    }
    
}

.rebel {
    .one-word-per-line {
        word-spacing: var( --item-big-width );
    }
    .teaser-element {
        max-width: var( --item-big-width );
    }
}
