/* animations */

@keyframes bounce {
    
  0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY( 0 );
          -ms-transform: translateY( 0 );
              transform: translateY( 0 );
  }
  40% {
      -webkit-transform: translateY( -10px );
          -ms-transform: translateY( -10px );
              transform: translateY( -10px );
  }
  60% {
      -webkit-transform: translateY( -5px );
          -ms-transform: translateY( -5px );
              transform: translateY( -5px );
  }

}

@keyframes fade-to-white {

    100% {
        fill: white;
    }
    
}

@keyframes fade-in {

    100% {
        opacity: 1;
    }
    
}
@keyframes fade-out {

    100% {
        opacity: 0;
    }
    
}
@keyframes slide-up {

    100% {
		margin: 0;
	}

}
@keyframes moveUp {
    100% { 
        -webkit-transform: translateY( calc( var( --header-height ) * -1 ) );
            -ms-transform: translateY( calc( var( --header-height ) * -1 ) );
                transform: translateY( calc( var( --header-height ) * -1 ) );
    }
}
@keyframes moveDown {
    0% { 
        -webkit-transform: translateY( calc( var( --header-height ) * -1 ) );
            -ms-transform: translateY( calc( var( --header-height ) * -1 ) );
                transform: translateY( calc( var( --header-height ) * -1 ) );
    }
    100% { 
        -webkit-transform: translateY( 0 );
            -ms-transform: translateY( 0 );
                transform: translateY( 0 );
    }
}
@keyframes pulseDot {
	0% {
		background: var( --tertiary-color );
		width: 5px;
		height: 5px;		
	}
	50% {
		background: black;
		width: 7px;
		height: 7px;		
	}
	100% {
		background: var( --tertiary-color );
		width: 5px;
		height: 5px;		
	}
}
@keyframes pulseBackground {
	0% {
		background: var( --tertiary-color );
	}
	50% {
		background: var( --background-color );
	}
	100% {
		background: var( --tertiary-color );
	}
}
